export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is the product for?"])},
        "icon-pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pro among pros"])},
        "icon-hr-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR Manager"])},
        "icon-recruiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiter"])},
        "icon-it-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Companies"])},
        "icon-agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment agencies"])},
        "icon-freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancers"])}
      },
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для кого предназначен продукт"])},
        "icon-pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессионалы своего дела"])},
        "icon-hr-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR специалист"])},
        "icon-recruiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекрутер"])},
        "icon-it-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Компании"])},
        "icon-agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенства"])},
        "icon-freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фрилансеры"])}
      }
    }
  })
}
