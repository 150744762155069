<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ToolsSectionItem from '@/components/sections/ToolsSection/ToolsSectionItem.vue';

const props = defineProps({
  limit: {
    type: Number,
    default: 1,
  },
  title: {
    type: String,
    required: true,
  },
  tools: {
    type: Array,
    required: true,
  },
  isNight: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const isNeedToShownMoreTools = ref(props.limit < props.tools.length);
const shownTools = computed(() => (isNeedToShownMoreTools.value
  ? props.tools.slice(0, props.limit)
  : props.tools)
  .map((tool) => ({
    ...tool,
    icon: props.isNight ? `${tool.icon}-dark` : tool.icon,
  })));
</script>

<template>
  <section class="container">
    <h3 class="title">
      {{ title }}
    </h3>
    <ToolsSectionItem
      v-for="tool in shownTools"
      :key="tool.title"
      :title="tool.title"
      :position="tool.position"
      :description="tool.description"
      :descriptionGeneral="tool.descriptionGeneral"
      :icon="tool.icon"
    />
    <div
      v-if="isNeedToShownMoreTools"
      class="tools__show-more"
    >
      <span
        class="tools__show-more-button"
        @click="isNeedToShownMoreTools = false"
      >
        {{ t('showMore') }}
      </span>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "~@/assets/styles/mixins";

.tools__show-more {
  text-align: center;
}

.tools__show-more-button {
  text-decoration: none;
  color: var(--text-special);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--text-special-hover)
  }
}

</style>

<i18n>
{
  "en": {
    "showMore": "Show more"
  },
  "ru": {
    "showMore": "Показать больше"
  }
}
</i18n>
