<script setup>
const emit = defineEmits(['input']);

defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
});

function handleInput(value) {
  emit('input', value.target.checked);
}
</script>

<template>
  <label class="checkbox">
    <input
      type="checkbox"
      :name="name"
      :checked="value"
      :disabled="disabled"
      @change="handleInput"
    >
    <span>
      {{ label }}
    </span>
  </label>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/mixins";

.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  input[type="checkbox"] {
    font-size: 30px;
    appearance: none;
    width: 2.5rem;
    height: 1.5rem;
    background: #ddd;
    border-radius: 2rem;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;
    margin: 0 1rem 0 0;

    &:checked {
      background: var(--button-background-color);
    }
    &[disabled]:checked {
      background: var(--button-background-color-disabled);
    }

    &:after {
      position: absolute;
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: #fff;
      -webkit-box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
      box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
      -webkit-transform: scale(.7);
      transform: scale(.7);
      left: 0;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
    }

    &:checked:after {
      left: calc(100% - 1.5rem);
    }
  }
}
</style>
