<script setup>
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import SvgIcon from '@/components/SvgIcon.vue';

const emit = defineEmits([
  'goToDownloadSection',
]);

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>

<template>
  <div class="header-title">
    <h2 class="header-title__general">Fury Ferret</h2>
    <p class="header-title__subtitle">
      {{ t('subtitle') }}
    </p>

    <Button
      class="header-title__button"
      icon="download"
      @click="emit('goToDownloadSection')"
      data-test-el="download-button-route"
      data-test-attr-from="header"
    >
      {{ t('downloadButton') }}
    </Button>

    <div class="header-description">
      <SvgIcon
        name="info"
        class="header-description__icon"
      />
      {{ t('description') }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/mixins";
$gap: 30px;

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  // old and atm actual safari have a problem with:
  //gap: 30px;
}

.header-title__general {
  color: var(--text-special);
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 0 15px;
  text-align: center;
}

.header-title__subtitle {
  margin: 0 0 $gap;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}

.header-title__button {
  margin: 15px 0 ($gap + 15);
}

.header-description {
  display: grid;
  grid-template-columns: 42px 1fr 42px;
  width: 100%;
  margin: 0;
  color: var(--text-default);
  font-size: 18px;
  line-height: normal;
  align-items: center;
  gap: 12px;
  background: var(--background-description);
  padding: 10px 12px;
  border-radius: 50px;
  min-height: 3rem;
  text-align: center;
  box-sizing: border-box;

  @media (max-width: $screen-max-sm) {
    grid-template-columns: 1fr;
  }
}

.header-description__icon {
  width: 42px;
  height: 42px;
  fill: #79AADB;
  @include isThemeNight {
    fill: #7B7B7B;
  }

  @media (max-width: $screen-max-sm) {
    display: none;
  }
}

</style>

<i18n>
{
  "en": {
    "subtitle": "Magic toolbox for recruiting",
    "downloadButton": "Download",
    "description": "Easy-to-use tools & Automating candidates selection"
  },
  "ru": {
    "subtitle": "Коробка с инструментами для рекрутинга",
    "downloadButton": "Download",
    "description": "Помогаем автоматизировать и упрощать процессы по поиску и подбору персонала"
  }
}
</i18n>
