export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fury Ferret – Recruitment tools"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent recruitment automation tools. We are discovering new methods and opportunities to help HR managers and recruiters."])}
  },
  "tools": {
    "checkNickname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check nickname"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant for a quick search for a candidate in the Internet space."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using only a nickname, the tool checks over 40 different services where a potential candidate might be registered."])}
    },
    "githubAnalytics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github Analytics"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzing a potential candidate's GitHub account."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tool structures information in a convenient format - it shows a list of technologies used, as well as existing email addresses."])}
    },
    "checkEmails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check email"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the candidate's email for existence."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tool allows you to keep your database up to date and not waste time on non-existent contact information."])}
    },
    "templateEngine": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Engine"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast delivery of your emails and messages."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tool allows you to create templates and give them convenient shortcuts."])}
    }
  },
  "cookies": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies to analyze your online preferences & personalize ad content. You’ll still see ads and by accepting the cookies you’ll help us personalize them for you. To manage your choices or reject optional cookies, click «Cookie Settings»."])},
    "policy": {
      "necessary": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessary cookies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These cookies ensure the correct operation of the site. They are enabled by default and cannot be disabled. If they are absent, you will not be able to fully use the important functions of the site."])}
      },
      "analytical": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical cookies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These cookies collect information about your visits and use of the site, which allows us to analyze and improve its performance in the future."])}
      },
      "marketing": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing cookies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These cookies are used to track visitors to the site. With this information, we will be able to show users interesting and relevant ads."])}
      },
      "other": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other cookies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cookies in this category have not yet been categorized and the purpose may be unknown at this time."])}
      }
    }
  }
}