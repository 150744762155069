<script setup>
import { useI18n } from 'vue-i18n';
import SvgIcon from '@/components/SvgIcon.vue';

const { t } = useI18n({});

defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  descriptionGeneral: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div
    class="grid tools-section-item"
    :class="`--${position}`"
  >
    <div class="column-width-4 column-width-mob-12">
      <SvgIcon
        :name="icon"
        class="tools-section-item__image"
      />
    </div>
    <div class="column-width-8 column-width-mob-12 tools-section-item__content">
      <h3 class="tools-section-item__subtitle">
        {{ t(title) }}
      </h3>
      <p
        v-if="descriptionGeneral"
        class="tools-section-item__text tools-section-item__general"
      >
        {{ t(descriptionGeneral) }}
      </p>
      <p class="tools-section-item__text">
        {{ t(description) }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/styles/mixins";

.tools-section-item {
  margin-bottom: 50px;

  @media (max-width: $screen-max-sm) {
    margin-bottom: 75px;
  }

  &.--right {
    direction: rtl;
    @media (max-width: $screen-max-sm) {
      direction: initial;
    }
  }
}

.tools-section-item__image {
  width: 100%;
  height: 100%;
}

.tools-section-item__subtitle {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 16px;
  text-align: left;
  direction: initial;
}

.tools-section-item__text {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  direction: initial;

  @media (max-width: $screen-max-sm) {
    font-size: 1rem;
  }
}

.tools-section-item__general {
  margin-bottom: 5px;
}

.tools-section-item__separator {
  @media (max-width: $screen-max-sm) {
    height: 3rem;
  }
}

.tools-section-item__content {
  background: var(--background-description);
  height: 100%;
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
