import { createRouter, createWebHistory } from 'vue-router';
import { languages } from '@/i18n';
import Landing from '../views/Landing.vue';

const defaultRoute = { name: 'Landing', params: { lang: languages[0] } };

const routes = [
  {
    path: '/',
    redirect: defaultRoute,
  },
  {
    path: '/:lang',
    name: 'Landing',
    component: Landing,
    beforeEnter: (to, from, next) => {
      if (languages.includes(to.params.lang)) {
        next();
        return;
      }
      next(defaultRoute);
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
