export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fury Ferret – Recruitment tools"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация процессов по подбору персонала. Упрощаем работу HR специалистам и рекрутерам, открывая новые методы и возможности по поиску сотрудников."])}
  },
  "tools": {
    "checkNickname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check nickname"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощник по быстрому поиску кандидата в интернет пространстве."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При помощи никнейма инструмент проверяет более 40 различных сервисов, где может быть зарегистрирован потенциальный кандидат."])}
    },
    "githubAnalytics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github Analytics"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ GitHub аккаунта потенциального кандидата."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструмент структурирует информацию в удобном формате – показывает список используемых технологий, а также существующие адреса электронной почты."])}
    },
    "checkEmails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check email"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка существования электронной почты кандидата."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструмент позволяет поддерживать вашу базу данных в актуальном состоянии и не тратить время на несуществующие контактные данные."])}
    },
    "templateEngine": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Engine"])},
      "descriptionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрая доставка ваших писем и сообщений."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструмент дает возможность создавать шаблоны и присваивать им удобные сочетания клавиш."])}
    }
  },
  "cookies": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем файлы cookie для анализа ваших предпочтений и персонализации рекламного контента. Отклонив файлы cookie вы по-прежнему будете видеть рекламу, но она может быть не релевантна для вас. Чтобы управлять своим выбором или отклонять необязательные файлы cookie, нажмите «Настройки cookie»."])},
    "policy": {
      "necessary": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательные cookie файлы"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это файлы, которые обеспечивают корректную работу сайта. Они включены по умолчанию и не могут быть отключены. При их отсутствии вы не сможете полноценно использовать важные функции сайта."])}
      },
      "analytical": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитические cookie файлы"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это файлы, осуществляющие сбор информации о ваших посещениях и использовании сайта, что в дальнейшем позволяет нам проанализировать и улучшить его работу."])}
      },
      "marketing": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркетинговые cookie файлы"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это файлы, которые используются для отслеживания посетителей на сайте. Благодаря этим данным мы сможем показывать интересную и релевантную рекламу пользователям."])}
      },
      "other": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие cookie файлы"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы cookie в этой категории еще не классифицированы, и в настоящее время их цель может быть неизвестна."])}
      }
    }
  }
}