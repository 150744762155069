<script setup>
import { languages } from '@/i18n';

const routersPath = languages.map((lang) => ({
  id: lang.toUpperCase(),
  hreflang: lang,
  to: {
    name: 'Landing',
    params: { lang },
  },
}));

</script>

<template>
  <div class="header-language">
    <router-link
      v-for="routePath in routersPath"
      :key="routePath.id"
      class="header-language__link"
      active-class="header-language__link-active"
      :to="routePath.to"
      :hreflang="routePath.hreflang"
    >
      {{ routePath.id }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/mixins";

.header-language {
  user-select: none;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem;
}

.header-language__link {
  color: var(--text-default);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.header-language__link-active {
  cursor: default;
  color: var(--button-background-color);
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}
</style>
