<script setup>

import { useI18n } from 'vue-i18n';
import WhoIsSectionItem from '@/components/sections/WhoIsSection/WhoIsSectionItem.vue';
import SvgIcon from '@/components/SvgIcon.vue';

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

defineProps({
  isNight: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div class="container">
    <section class="section who-is__section">
      <h3 class="title who-is__title">
        {{ t('title') }}
      </h3>

      <!-- top arrows -->
      <div class="who-is-section__arrows-top-wrapper">
        <div class="who-is-section__arrows-common-wrapper who-is-section__arrows-top">
          <SvgIcon
            class="who-is-section__arrows-common"
            name="who-is/arrows-top"
          />
        </div>
      </div>
      <!-- zero level -->
      <div class="grid who-is-section__level-1">
        <div class="column-start-5 column-end-8">
          <WhoIsSectionItem icon-name="professionals">
            <span class="who-is-section__level-1-text">
              {{ t('icon-pro') }}
            </span>
          </WhoIsSectionItem>
        </div>
      </div>
      <!-- first level -->
      <div class="grid">
        <div class="column-start-1 column-end-4">
          <WhoIsSectionItem class-icon-name="hr-manager" :is-block="true">
            {{ t('icon-hr-manager') }}
          </WhoIsSectionItem>
        </div>
        <div class="column-start-9 column-end-12">
          <WhoIsSectionItem class-icon-name="recruiter" :is-block="true">
            {{ t('icon-recruiter') }}
          </WhoIsSectionItem>
        </div>
      </div>

      <div class="who-is-section__arrows-common-wrapper">
        <SvgIcon
          class="who-is-section__arrows-common"
          name="who-is/arrows-common"
        />
      </div>

      <div class="grid">
        <div class="column-start-1 column-end-4">
          <WhoIsSectionItem icon-name="it-companies">
            {{ t('icon-it-company') }}
          </WhoIsSectionItem>
        </div>
        <div class="column-start-5 column-end-8">
          <WhoIsSectionItem icon-name="agencies">
            {{ t('icon-agency') }}
          </WhoIsSectionItem>
        </div>
        <div class="column-start-9 column-end-12">
          <WhoIsSectionItem icon-name="freelance">
            {{ t('icon-freelance') }}
          </WhoIsSectionItem>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/styles/mixins";

.who-is-section__level-1 {
  margin-bottom: -12%;

  @media (max-width: $screen-max-sm) {
    margin-bottom: 19%;
  }

  &:before {
    content: " ";
    position: absolute;
    background: var(--background);
    width: 24%;
    height: 19%;
    left: 38%;

    @media (max-width: $screen-max-sm) {
      content: initial;
      width: 0;
      height: 0;
    }
  }
}

.who-is-section__level-1-text {
  @media (max-width: $screen-max-sm) {
    position: absolute;
    left: -100px;
    right: -100px;
    margin-top: -25px;
    display: block;
    top: 25px;
  }
}

.who-is-section__arrows-common-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 18%;
  margin: 0 0 3%;
}

.who-is-section__arrows-common {
  width: 100%;
  height: 100%;
  position: absolute;
  fill: var(--arrow-color);
}

.who-is-section__arrows-top-wrapper {
  position: relative;
  z-index: -1;
}

.who-is-section__arrows-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -2;

  @media (max-width: $screen-max-sm) {
    padding-top: 18%;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Who is the product for?",
    "icon-pro": "The pro among pros",
    "icon-hr-manager": "HR Manager",
    "icon-recruiter": "Recruiter",
    "icon-it-company": "IT Companies",
    "icon-agency": "Employment agencies",
    "icon-freelance": "Freelancers"
  },
  "ru": {
    "title": "Для кого предназначен продукт",
    "icon-pro": "Профессионалы своего дела",
    "icon-hr-manager": "HR специалист",
    "icon-recruiter": "Рекрутер",
    "icon-it-company": "IT Компании",
    "icon-agency": "Агенства",
    "icon-freelance": "Фрилансеры"
  }
}
</i18n>
