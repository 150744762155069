<script setup>
import { useRouter } from 'vue-router';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import i18n from '@/i18n';
import Cookies from '@/components/Cookies.vue';

const { t } = useI18n({});

const router = useRouter();
const html = document.documentElement;

function setLanguageAttribute() {
  const { lang = '' } = router.currentRoute.value.params;
  if (lang) {
    html.setAttribute('lang', lang);
  } else {
    html.removeAttribute('lang');
  }
}

setLanguageAttribute();

watch(router.currentRoute, () => {
  const { lang } = router.currentRoute.value.params;
  // eslint-disable-next-line no-underscore-dangle
  if (i18n.global.locale?._setter) {
    // eslint-disable-next-line no-underscore-dangle
    i18n.global.locale._setter(lang);
  } else {
    // todo didn't work
    i18n.global.locale = lang;
  }

  setLanguageAttribute();
});
</script>

<template>
  <teleport to="head">
    <title>{{ t('meta.title') }}</title>
    <meta name="description" :content="t('meta.description')">

    <!-- Facebook Meta Tags -->
    <meta property="og:type" content="website">
    <meta property="og:image" content="/furyferret.png">

    <!-- Twitter Meta Tags -->
    <meta name="twitter:card" content="summary_large_image">
    <meta property="twitter:domain" content="furyferret.com">
    <meta name="twitter:image" content="/furyferret.png">
  </teleport>
  <router-view/>
  <Cookies/>
</template>

<style lang="scss">
@import "~normalize-scss/sass/normalize";
@import "~@/assets/styles/themes.scss";
@import "~@/assets/styles/grid.scss";
@import "~@/assets/styles/main.scss";

@include normalize;
</style>
