<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import SeparateLine from '@/components/SeparateLine.vue';
import WhoIsSection from '@/components/sections/WhoIsSection.vue';
import ToolsSection from '@/components/sections/ToolsSection.vue';
import DownloadSection from '@/components/sections/DownloadSection.vue';

import { THEME_NIGHT } from '@/store/modules/theme';

const store = useStore();
const refDownloadSection = ref(null);

const applications = [
  {
    limit: 3,
    title: 'Recruitment tools',
    tools: [
      {
        position: null,
        icon: 'tools/checkNickname',
        title: 'tools.checkNickname.title',
        descriptionGeneral: 'tools.checkNickname.descriptionGeneral',
        description: 'tools.checkNickname.description',
      },
      {
        position: null,
        icon: 'tools/templateEngine',
        title: 'tools.templateEngine.title',
        descriptionGeneral: 'tools.templateEngine.descriptionGeneral',
        description: 'tools.templateEngine.description',
      },
      {
        position: null,
        icon: 'tools/githubAnalytics',
        title: 'tools.githubAnalytics.title',
        descriptionGeneral: 'tools.githubAnalytics.descriptionGeneral',
        description: 'tools.githubAnalytics.description',
      },
      {
        position: null,
        icon: 'tools/checkEmails',
        title: 'tools.checkEmails.title',
        descriptionGeneral: 'tools.checkEmails.descriptionGeneral',
        description: 'tools.checkEmails.description',
      },
    ].map((tool, index) => ({
      ...tool,
      position: index % 2 ? 'right' : 'left',
    })),
  },
];

// const version = '1.2.1';
// const release = new Date('2021-10-30T14:01:00.578Z').getTime();
const version = '1.2.2';
const release = new Date('2022-02-13T14:01:00.578Z').getTime();
const windowsLink = `https://furyferret.com/download/Recruitment%20Tools%20Setup%20${version}.exe`;
const macOsLink = `https://furyferret.com/download/Recruitment%20Tools-${version}.dmg`;

const isNight = computed(() => store.state.theme.theme === THEME_NIGHT);

function handleToggleTheme() {
  store.dispatch('theme/toggle');
}

function handleDownloadSection() {
  refDownloadSection.value.refDownloadSectionTitle.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}

store.dispatch('theme/setDefault');
window.matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', () => {
    store.dispatch('theme/setDefault');
  });
</script>

<template>
  <Header
    @toggleTheme="handleToggleTheme"
    @goToDownloadSection="handleDownloadSection"
  />
  <SeparateLine/>
  <WhoIsSection
    :isNight="isNight"
  />
  <SeparateLine/>
  <ToolsSection
    v-for="({limit, title, tools}) in applications"
    :isNight="isNight"
    :key="title"
    :limit="limit"
    :title="title"
    :tools="tools"
  />
  <SeparateLine/>
  <DownloadSection
    ref="refDownloadSection"
    :windows-link="windowsLink"
    :mac-os-link="macOsLink"
    :version="version"
    :release="release"
  />
  <Footer/>
</template>
