export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Distributor"])},
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© 2019 - ", _interpolate(_named("year")), " FuryFerret. All rights reserved."])}
      },
      "ru": {
        "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дистрибьютор приложения"])},
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© 2019 - ", _interpolate(_named("year")), " FuryFerret. Все права защищены."])}
      }
    }
  })
}
