<script setup>
import SvgIcon from '@/components/SvgIcon.vue';

const props = defineProps({
  iconName: {
    type: String,
    default: '',
  },
  classIconName: {
    type: String,
    default: '',
  },
  isBlock: {
    type: Boolean,
    default: false,
  },
});

const classImage = [
  'who-is-section-item__default-image',
  `--${props.classIconName}`,
];
const classLabel = {
  'who-is-section-item__label': true,
  'who-is-section-item__label-block': props.isBlock,
};
</script>

<template>
  <div
    v-if="classIconName"
    :class="classImage"
  />
  <div
    v-if="iconName"
    class="who-is-section-item__default-icon-wrapper"
  >
    <SvgIcon
      :name="`who-is/${iconName}`"
      class="who-is-section-item__default-icon"
    />
  </div>
  <div :class="classLabel">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/mixins";

.who-is-section-item__default-icon-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 71%;
  height: 0;
}

.who-is-section-item__default-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.who-is-section-item__default-image {
  padding: 38%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  &.--hr-manager {
    background-image: url("~@/assets/who-is/hr-manager-light.svg");

    @include isThemeNight {
      background-image: url("~@/assets/who-is/hr-manager.svg");
    }
  }

  &.--recruiter {
    background-image: url("~@/assets/who-is/recruiter-light.svg");

    @include isThemeNight {
      background-image: url("~@/assets/who-is/recruiter.svg");
    }
  }
}

.who-is-section-item__label {
  position: relative;
  text-align: center;
  margin-top: 7%;

  @media (max-width: $screen-max-sm) {
   font-size: 0.9rem;
  }
}

.who-is-section-item__label-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 2px;
  background-color: #6D9FD0;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-shadow: var(--shadow-on-block);

  @include isThemeNight {
    background-color: #7B7B7B;
  }
}
</style>
