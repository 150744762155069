<script setup>
import SvgIcon from '@/components/SvgIcon.vue';

function handleClick(event) {
  const block = Math.round((event.clientY / event.screenY) * 100) > 30
    ? 'start'
    : 'end';
  event.target.scrollIntoView({
    behavior: 'smooth',
    block,
  });
}
</script>

<template>
  <div class="container">
    <div class="separate-line">
      <div class="separate-line__line"/>
      <SvgIcon
        @click="handleClick"
        class="separate-line__icon"
        name="divider"
      />
      <div class="separate-line__line"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.separate-line {
  display: flex;
  margin: 75px 0;
}

.separate-line__line {
  height: 1px;
  width: 100%;
  margin-top: 12px;
  background-color: var(--light-gray);
}

.separate-line__icon {
  cursor: pointer;
  width: 100px;
  height: 24px;
  fill: var(--light-gray);
  transition: width 300ms;

  &:hover {
    width: 130px;
  }
}
</style>
