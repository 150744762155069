<script>
const GoogleAnalytics = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_ANALYTICS}`;
  document.head.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS);
};

export default GoogleAnalytics;
</script>
