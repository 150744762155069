<template>
<div class="notice">
  <slot/>
</div>
</template>

<style scoped>
.notice {
  background: var(--background-description);
  height: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  text-align: center;
  margin: 2rem 0;
}
</style>
